/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Table as TableAnt, Modal, Button as BoutonAnt, Select as SelectAnt } from 'antd';
import { EditOutlined, DeleteOutlined, PlusSquareOutlined, SearchOutlined, CameraOutlined } from '@ant-design/icons';

import { DELETE, GET, POST } from '../../../components/Utils/requete';
import ExportExcel from '../../../components/organism/ExportTableau/exportExcel';
import { suppressionDoublon } from '../../../components/Utils/suppressionDoublon';
import Contact from '../../../components/organism/Contact/Contact';
import ExcuseEleveAtelier from '../../../components/organism/ExcuseEleve/excuseEleveAtelier';
import { testDroit } from '../../../components/Utils/testDroit';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

import { tableHeadInfoEleveExport } from '../consts';
import * as fonctionAtelier from '../../../fonctionsRoles/atelier';

import dayjs from 'dayjs';
import formatSelect from '../../../components/Utils/FormatSelect';

const AtelierEleves = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const css = useStylesMaterielUIGlobal();
    const [tableauFiltre, setTableauFiltre] = useState({
        fk_statut: [],
        fk_instrument: [],
        Eleve: [],
        fk_scolariteInterne: [],
    });
    const [dataEleve, setDataEleve] = useState([]);
    const [open, setOpen] = useState({ open: false });
    const [mail, setMail] = useState([]);
    const [dataSI, setDataSI] = useState();

    async function ElevesAtelier() {
        const responseEleve = await POST('/api/ateliers/elevesByAteliers', {
            fk_atelier: props.id,
        });
        let tableauMail = [];
        for (let elem = 0; elem < responseEleve.data.length; elem++) {
            tableauMail.push(responseEleve.data[elem].concatEmails);
        }
        setMail(tableauMail);
        setDataEleve(responseEleve.data);
    }

    async function associerEleve() {
        if (dataSI.fk_instrument === '' || dataSI.fk_statut === '' || dataSI.fk_scolariteInterne === '') {
            return props.notification({ message: 'Tous les champs doivent étre rempli' });
        } else {
            dataSI.fk_atelier = props.id;
            const response = await POST('/api/ateliers/attachedEleve', dataSI);
            if (response.success) {
                setDataSI();
                setOpen({ open: false });
            } else {
                return props.notification(response.message);
            }
        }
        return ElevesAtelier();
    }

    async function dataSelect() {
        const statut = await GET('/api/ateliers/ateliersStatut');
        const dataEleve = {
            fk_anneeScolaire: props.anneeScolaire,
            fk_atelierType: props.sousGroupeAtelier,
        };
        const eleveFiltre = await POST('/api/eleves/listElevesFromAnneeScolaireAndAtelierType/', dataEleve);
        const dataInstrument = {
            fk_anneeScolaire: props.anneeScolaire,
            fk_site: [props.site],
        };
        const instrumentFiltre = await POST('/api/cotisations/instruments', dataInstrument);
        setTableauFiltre((prevState) => ({
            ...prevState,
            fk_instrument: instrumentFiltre.data,
            Eleve: eleveFiltre.data,
            fk_statut: statut.data,
        }));
    }

    async function suppressionEleve(idUser) {
        const data = {
            fk_eleve: idUser,
            fk_atelier: props.id,
        };
        await DELETE('/api/ateliers/attachedEleve', data);
        setOpen({ open: false });
        ElevesAtelier();

        setOpen({ open: false });
        return ElevesAtelier();
    }
    function selectInfoSI(array, fk_scolariteInterne) {
        const infoFiltre = array.filter((e) => +e.idScolarite === +fk_scolariteInterne);
        if (infoFiltre !== 0) {
            setDataSI((prevState) => ({
                ...prevState,
                fk_instrument: infoFiltre[0].fk_instrument,
                fk_statut: infoFiltre[0].fk_statut,
            }));
        }
    }

    async function filtreAddEleve(id, lecture) {
        const response = await GET(`/api/eleves/${id}/SI_list?fk_anneeScolaire=${props.anneeScolaire}`);
        setTableauFiltre((prevState) => ({
            ...prevState,
            fk_scolariteInterne: response.data,
        }));
        if (!lecture && response.data.length !== 0) {
            setDataSI((prevState) => ({
                ...prevState,
                fk_eleve: id,
            }));
            if (response.data.length < 2) {
                setDataSI((prevState) => ({
                    ...prevState,
                    fk_scolariteInterne: response.data[0].idScolarite,
                }));
                selectInfoSI(response.data, response.data[0].idScolarite);
            }
        }
    }
    function footerModal() {
        const bouton = [
            <BoutonAnt
                danger
                key="back"
                onClick={() => {
                    setDataSI();
                    setOpen({ open: false, content: '', width: open.width });
                }}
            >
                Retour
            </BoutonAnt>,
        ];
        if (open.event !== 'infoEleve')
            bouton.unshift(
                <BoutonAnt
                    className="cbleu border1bleu mr2 xs_w90"
                    key="back"
                    onClick={() => {
                        if (open.event === 'supprEleve') suppressionEleve(open.params);
                        if (open.event === 'addEleve' || open.event === 'updateEleve') associerEleve();
                    }}
                >
                    Valider
                </BoutonAnt>,
            );
        return bouton;
    }
    useEffect(() => {
        ElevesAtelier();
        dataSelect();
    }, []);

    const columns = [
        {
            render: (text, data) => (
                <div style={{ width: `${(window.innerWidth / 100) * 90}px` }}>
                    <div className="flex spaceBetween borderBottom">
                        <span className="mr5">Elève : </span>
                        {testDroit(user[usurpation].roleFonction, fonctionAtelier.Lien_profil_enseignant) ? (
                            <Link className="fontSize0_9em" to={`/fiche-eleve/${data.fk_eleve}`}>
                                {data.nomPrenomEleve}
                            </Link>
                        ) : (
                            data.nomPrenomEleve
                        )}
                    </div>
                    <div className="flex spaceBetween ">
                        <span className="mr5">Instrument : </span>
                        <span>{data.libelleInstrument} </span>
                    </div>
                    <div className="flex spaceBetween ">
                        <span className="mr5">Statut : </span>
                        <span>{data.libelleStatut} </span>
                    </div>
                    <div className="flex spaceBetween ">
                        <span className="mr5">Date d'inscription : </span>
                        <span>{dayjs(data.dateCreation).format('DD/MM/YYYY')} </span>
                    </div>
                    <div className="flex spaceBetween ">
                        <span className="mr5">Droit image : </span>
                        <span>{data.etatDroitImage ? 'OUI' : 'NON'} </span>
                    </div>
                </div>
            ),
            responsive: ['xs'],
        },
        {
            title: 'Elève',
            render: (text, data) => (
                <div className="flex spaceBetween">
                    {testDroit(user[usurpation].roleFonction, fonctionAtelier.Lien_profil_enseignant) ? (
                        <Link className="fontSize0_9em" to={`/fiche-eleve/${data.fk_eleve}`}>
                            {data.nomPrenomEleve}
                        </Link>
                    ) : (
                        data.nomPrenomEleve
                    )}
                    <div className="w30">
                        <EditOutlined
                            className="fontSize17 cBleu2c70c6 mr5"
                            onClick={() => {
                                filtreAddEleve(data.fk_eleve, true);
                                setDataSI({
                                    fk_instrument: data.fk_instrument,
                                    fk_statut: data.fk_statut,
                                    fk_scolariteInterne: data.fk_scolariteInterne,
                                    fk_eleve: data.fk_eleve,
                                });
                                setOpen({
                                    open: true,
                                    event: 'updateEleve',
                                    width: '40%',
                                });
                            }}
                        />
                        <DeleteOutlined className=" cRed" onClick={() => setOpen({ open: true, event: 'supprEleve', params: data.fk_eleve, width: '25%' })} />
                        {testDroit(user[usurpation].roleFonction, fonctionAtelier.Presences_élève) && props.dataAtelier.jour && (
                            <ExcuseEleveAtelier dataAtelier={props.dataAtelier} idEleve={data.fk_eleve} nomEleve={data.nomPrenomEleve} />
                        )}
                    </div>
                </div>
            ),
            responsive: ['sm'],
        },
        {
            title: 'Instrument',
            dataIndex: 'libelleInstrument',
            responsive: ['sm'],
        },
        {
            title: 'Statut',
            dataIndex: 'libelleStatut',
            width: '0em',
            responsive: ['sm'],
        },
        {
            title: "Date d'inscription",
            render: (text, props) => dayjs(props.dateCreation).format('DD/MM/YYYY'),
            width: '9em',
            responsive: ['sm'],
        },
        {
            render: (text, props) => <CameraOutlined className={`fontSize20 ${props.etatDroitImage ? 'cVert' : 'cRed'}`} />,
            width: '0em',
            responsive: ['sm'],
        },
    ];
    const columnsEleve = [
        {
            title: 'Élèves',
            dataIndex: 'nomPrenomEleve',
            responsive: ['sm'],
        },
        {
            title: 'Statut',
            dataIndex: 'libelleStatut',
            width: '0em',
            responsive: ['sm'],
        },
        {
            title: 'Mobile',
            dataIndex: 'telephoneMobileEleve',
            render: (text, data) => suppressionDoublon(`${data.concatTelephoneMobile},${data.concatTelephoneFixe}`).map((e) => <Contact telephone={e} />),
            width: '9em',
            responsive: ['sm'],
        },
        {
            title: 'Email',
            dataIndex: 'concatEmails',
            render: (text, data) => suppressionDoublon(data.concatEmails).map((e) => <Contact email={e} />),
            width: '9em',
            responsive: ['sm'],
        },
    ];
    return (
        <div>
            <Modal
                width={open.width}
                open={open.open}
                onCancel={() => {
                    setDataSI();
                    setOpen({ open: false, content: '', width: open.width });
                }}
                closeIcon={false}
                footer={footerModal()}
            >
                {open.event === 'supprEleve' && <h3>Voulez-vous supprimer cet élève</h3>}
                {(open.event === 'addEleve' || open.event === 'updateEleve') && (
                    <>
                        {open.event === 'addEleve' && (
                            <>
                                <h3>Ajouter un élève</h3>
                                <div className="w100 flex spaceBetween alignICenter mb2">
                                    <label className="w25">Elève:</label>
                                    <SelectAnt
                                        className="w60"
                                        showSearch
                                        options={formatSelect(tableauFiltre.Eleve, 'idEleve', 'nomPrenomEleve')}
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        onChange={(value) => filtreAddEleve(value)}
                                    />
                                </div>
                            </>
                        )}
                        {open.event === 'updateEleve' && dataSI && (
                            <h3>Modifier les informations de {tableauFiltre.Eleve.filter((e) => e.idEleve === dataSI.fk_eleve)[0].nomPrenomEleve}</h3>
                        )}{' '}
                        <div className="w100 flex spaceBetween alignICenter mb2">
                            <label className=" w25">Scolarité Interne:</label>
                            <SelectAnt
                                className="w60"
                                value={dataSI?.fk_scolariteInterne}
                                onChange={(values) => {
                                    setDataSI((prevState) => ({ ...prevState, fk_scolariteInterne: values }));
                                    selectInfoSI(tableauFiltre.fk_scolariteInterne, values);
                                }}
                                options={formatSelect(tableauFiltre.fk_scolariteInterne, 'idScolarite', 'libelleOffre')}
                            />
                        </div>
                        <div className="w100 flex spaceBetween alignICenter mb2">
                            <label className=" w25">Instrument:</label>
                            <SelectAnt
                                className="w60"
                                value={dataSI?.fk_instrument}
                                onChange={(values) => setDataSI((prevState) => ({ ...prevState, fk_instrument: values }))}
                                options={formatSelect(tableauFiltre.fk_instrument, 'idInstrument', 'libelleInstrument')}
                            />
                        </div>
                        <div className="w100 flex spaceBetween alignICenter mb2">
                            <label className=" w25">Statut:</label>
                            <SelectAnt
                                className="w60"
                                value={dataSI?.fk_statut}
                                onChange={(values) => setDataSI((prevState) => ({ ...prevState, fk_statut: values }))}
                                options={formatSelect(tableauFiltre.fk_statut, 'idStatut', 'libelleStatut')}
                            />
                        </div>
                    </>
                )}
                {open.event === 'infoEleve' && (
                    <>
                        <div className="flex alignICenter mb2">
                            <h3 className="w50 mb0">Détail des élèves de l'atelier</h3>
                            <div className="flex w100 flexEnd">
                                {testDroit(user[usurpation].roleFonction, fonctionAtelier.Export_excel) && (
                                    <ExportExcel data={dataEleve} head={tableHeadInfoEleveExport} nomFichier="Liste élèves" />
                                )}
                                {testDroit(user[usurpation].roleFonction, fonctionAtelier.Envoi_email) && (
                                    <BoutonAnt className="cbleu border1bleu mr2 xs_w90">
                                        <Contact email={mail} lib="Email à tous" />
                                    </BoutonAnt>
                                )}
                            </div>
                        </div>
                        <TableAnt
                            columns={columnsEleve}
                            dataSource={dataEleve}
                            pagination={{
                                showTotal: (total, range) => `${range[0]}-${range[1]} / ${total} `,
                                position: ['topRight'],
                                total: dataEleve?.length,
                                defaultCurrent: 1,
                                defaultPageSize: 10,
                            }}
                        />
                    </>
                )}
            </Modal>
            <div className="flex mt5">
                <h3 className="w100 nomargin">Élèves </h3>
                {testDroit(user[usurpation].roleFonction, fonctionAtelier.Associer_eleve) && !window.matchMedia('(max-width: 576px)').matches && (
                    <SearchOutlined
                        className="cVert fontSize20 mr2"
                        onClick={() => {
                            setOpen({ open: true, event: 'infoEleve', width: '80%' });
                        }}
                    />
                )}
                {testDroit(user[usurpation].roleFonction, fonctionAtelier.Associer_eleve) && !window.matchMedia('(max-width: 576px)').matches && (
                    <PlusSquareOutlined className="cVert fontSize20" onClick={() => setOpen({ open: true, event: 'addEleve' })} disabled={+props.id === 0} />
                )}
            </div>
            {+props.maximumEleves > 0 ? <p className="nomargin fontSize0_9em">nombre d'élève {`${dataEleve.length}/${props.maximumEleves}`}</p> : null}
            <hr></hr>
            <TableAnt
                columns={columns}
                dataSource={dataEleve}
                pagination={
                    dataEleve.length > 10 && {
                        showTotal: (total, range) => `${range[0]}-${range[1]} / ${total} `,
                        position: ['topRight'],
                        total: dataEleve?.length,
                        defaultCurrent: 1,
                        defaultPageSize: 10,
                    }
                }
            />
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}

export default connect(null, mapDispatchToProps)(AtelierEleves);
