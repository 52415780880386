/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Vijaya Srikar PORALLA
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Table, TableBody, TableCell, TableRow, TableContainer, Paper, Button, IconButton } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';

import Container from '../../components/atoms/Container/container';
import { GET, POST, DELETE } from '../../components/Utils/requete';
import PaginationTable from '../../components/organism/Table/Pagination';
import TableRecherche from '../../components/organism/Table/TableRecherche';
import TableHeadCustom from '../../components/organism/Table/TableHead';
import setData from '../../components/organism/Table/setDataTableau';
import SelectSimple from '../../components/organism/SelectSimple/SelectSimple';
import SelectMultiple from '../../components/organism/SelectMultiple/SelectMultiple';
import euroFormate from '../../components/Utils/euroFormate';
import DialogCustom from '../../components/organism/Dialog/dialog';
import DateInput from '../../components/organism/Input/date';
import Input from '../../components/atoms/TextField';
import DateHeureFormat from '../../components/Utils/DateHeureFormat';
import { ExportCotisation } from '../../components/organism/Export/miseEnFormeTableauExport';
import SplitButton from '../../components/organism/BoutonDrop/BoutonDrop';
import CalculCotisation from '../../components/organism/CalculCotisation/calculCotisation';
import { testDroit } from '../../components/Utils/testDroit';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

import { tableHead, tableauHeadFacture } from './consts';
import * as fonctionCotisation from '../../fonctionsRoles/cotisationEtReglement';

const Cotisation = (props) => {
    const css = useStylesMaterielUIGlobal();
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const reducerFiltre = useSelector((state) => state.SelectSimple);
    const reducerFiltreMultiple = useSelector((state) => state.SelectMultiple);
    const refreshSimple = useSelector((state) => state.SelectSimple.refreshSimple);
    const refreshMultiple = useSelector((state) => state.SelectMultiple.refreshMultiple);
    const [dataCotisation, setDataCotisation] = useState([]);
    const [reglementOuvert, setReglementOuvert] = useState(false);
    const [open, setOpen] = useState({ open: false });
    const [valider, setValider] = useState(false);
    const [valeurRecherche, setValeurRecherche] = useState({
        refresh: false,
        valeur: '',
    });
    const [dataInput, setDataInput] = useState({ quittance: '', montant: '', datePaiment: '' });
    const [tableauFiltre, setTableauFiltre] = useState({
        anneeScolaire: [],
        reglement: [],
        site: [],
    });
    const [displayRefus, setDisplayRefus] = useState('none');
    const [paiementRefus, setPaimentRefus] = useState([]);

    function Modal() {
        let content = <></>;
        switch (open.content) {
            case 'save':
                content = (
                    <>
                        <DateInput
                            label={'Date de paiement'}
                            format="dd/MM/yyyy"
                            value={!dataInput.datePaiment ? new Date() : dataInput.datePaiment}
                            className={[css.nomargin, css.ml30px, css.w50]}
                            classeDiv="textLeft alignICenter flex mb2"
                            onChange={(event) =>
                                setDataInput((prevState) => ({
                                    ...prevState,
                                    datePaiment: new Date(event),
                                }))
                            }
                        />
                        <div className=" alignICenter flex mb2">
                            <label className={'w25'}>Mode de paiement</label>
                            <SelectSimple tableau={tableauFiltre.reglement} indice={'Reglement'} classe={[css.nomargin, css.ml30px, css.w50]}></SelectSimple>
                        </div>
                        <div className=" alignICenter flex mb2">
                            <label className={'w25'}>Montant</label>
                            <Input
                                value={dataInput.montant}
                                className={[css.nomargin, css.ml30px, css.w50]}
                                onChange={(event) => {
                                    if (event.target.value < 0) {
                                        props.notification({ message: 'Les valeurss negative ne sont pas autorisé' });
                                    } else {
                                        setDataInput((prevState) => ({
                                            ...prevState,
                                            montant: event.target.value,
                                        }));
                                    }
                                }}
                            />
                        </div>
                        <div className=" alignICenter flex mb2">
                            <label className={'w25'}>Quittance</label>
                            <Input
                                value={dataInput.quittance}
                                className={[css.nomargin, css.ml30px, css.w50]}
                                onChange={(event) =>
                                    setDataInput((prevState) => ({
                                        ...prevState,
                                        quittance: event.target.value,
                                    }))
                                }
                            />
                        </div>
                        {dataInput.abonnement &&
                        testDroit(user[usurpation].roleFonction, fonctionCotisation.Resilier_abonnement) &&
                        dataInput.abonnement.charAt(0) !== '-' ? (
                            <ButtonCustom type={'button'} className={[css.m2]} label={"Résilier l'abonnement"} onClick={() => resiliation(open.idReglement)} />
                        ) : null}
                        <div className="mb2">
                            <span className="fontSmallGrey mb2">{`Reste à payer divisé par 2 = ${+dataInput.montant / 2}`}</span>
                        </div>
                        <ButtonCustom type={'button'} label={'Valider'} onClick={() => saveReglement(open.idCotisation, open.idReglement)} />
                        <ButtonCustom
                            type={'button'}
                            suppression={'annuler'}
                            className={css.ml2}
                            onClick={() => {
                                setOpen({ open: false });
                            }}
                        />
                    </>
                );
                break;
            case 'delete':
                content = (
                    <>
                        <span className="mr2">Voulez vous supprimer ce reglement ?</span>
                        <div className="mt2 textCenter">
                            <ButtonCustom type={'button'} label={'Oui'} onClick={() => deleteReglement(open.idReglement)} />
                            <ButtonCustom
                                type={'button'}
                                suppression={'Non'}
                                className={css.ml2}
                                onClick={() => {
                                    setOpen({ open: false });
                                }}
                            />
                        </div>
                    </>
                );
                break;
            default:
                break;
        }
        return (
            <DialogCustom
                open={open.open}
                onClose={() => {
                    setOpen({ open: false });
                }}
                maxWidth="lg"
                children={content}
                fullScreen={false}
            />
        );
    }

    function ButtonCustom(props) {
        if (props.type === 'button') {
            return (
                <Button
                    variant="outlined"
                    size="small"
                    color={props.suppression ? 'secondary' : 'primary'}
                    className={props.className ? props.className : undefined}
                    onClick={props.onClick}
                >
                    {props.suppression ? props.suppression : props.label}
                </Button>
            );
        }
        if (props.type === 'icone') {
            return (
                <IconButton
                    className={props.className ? props.className : undefined}
                    onClick={props.onClick}
                    color={props.suppression ? 'secondary' : 'primary'}
                >
                    {props.suppression ? props.suppression : props.label}
                </IconButton>
            );
        }
    }

    function Boutons(props) {
        const data = {
            quittance: props.detail[4],
            montant: props.detail[1],
            datePaiment: props.detail[2],
        };
        if (props.detail.length === 8) {
            data.abonnement = props.detail[7];
        }
        if (reglementOuvert) {
            return (
                <>
                    {testDroit(user[usurpation].roleFonction, fonctionCotisation.Modifier_un_reglement) ? (
                        <ButtonCustom
                            className={css.p0}
                            onClick={() => {
                                setOpen({
                                    open: true,
                                    idCotisation: props.idCotisation,
                                    idReglement: props.detail[0],
                                    content: 'save',
                                });
                                setDataInput(data);
                                reglementFiltre({ Reglement: props.detail[6] });
                                setValider(+props.detail[3] === 1 ? true : false);
                            }}
                            type="icone"
                            label={<EditIcon />}
                        />
                    ) : null}
                    {testDroit(user[usurpation].roleFonction, fonctionCotisation.Supprimer_un_reglement) && !window.matchMedia('(max-width: 576px)').matches ? (
                        <ButtonCustom
                            className={css.p0}
                            onClick={() =>
                                setOpen({
                                    open: true,
                                    idReglement: props.detail[0],
                                    content: 'delete',
                                })
                            }
                            type="icone"
                            suppression={<DeleteForeverIcon />}
                        />
                    ) : null}
                </>
            );
        } else {
            return <></>;
        }
    }

    function TableauDetailPaiment(props) {
        if (props.data) {
            let body;
            let tableauStyle;
            let tableauHeader = <></>;
            if (props.detail === 'facture') {
                tableauStyle = { backgroundColor: '#ebebeb' };
                tableauHeader = <TableHeadCustom data={tableauHeadFacture} sort={false}></TableHeadCustom>;
                body = (
                    <TableBody>
                        {props.data.split('|-|').map((e) => {
                            const detail = e.split('|');
                            return (
                                <TableRow>
                                    <TableCell component="th" scope="row" className={css.p0}>
                                        <h5 className="ml5">{euroFormate(detail[1])}</h5>
                                    </TableCell>
                                    <TableCell component="th" scope="row" className={css.p0}>
                                        <h5>{detail[5]}</h5>
                                    </TableCell>
                                    <TableCell component="th" scope="row" className={css.p0}>
                                        <h5>{detail[2]}</h5>
                                    </TableCell>
                                    <TableCell component="th" scope="row" className={css.p0}>
                                        <h5 className="nomargin">{`Quittance: ${detail[4]}`}</h5>
                                        {detail[7] ? (
                                            <h5 className="nomargin">
                                                {`Abonnement: ${detail[7].charAt(0) !== '-' ? detail[7] : detail[7].substring(1)} ${
                                                    detail[7].charAt(0) !== '-' ? '(Actif)' : '(Résilier)'
                                                }`}
                                            </h5>
                                        ) : null}
                                    </TableCell>
                                    <TableCell component="th" scope="row" className={css.p0}>
                                        <Boutons idCotisation={props.idCotisation} detail={detail} />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                );
                if (window.matchMedia('(max-width: 576px)').matches) {
                    body = (
                        <TableBody>
                            {props.data.split('|-|').map((e) => (
                                <TableRow>
                                    {`Montant:      ${e.split('|')[1]}\n`}
                                    {`Mode:         ${e.split('|')[5]}\n`}
                                    {`Date:         ${e.split('|')[2]}\n`}
                                    {`Valide:       ${+e.split('|')[3] === 1 ? 'oui' : 'non'}\n`}
                                    {`Quittance:    ${e.split('|')[4]}\n`}
                                </TableRow>
                            ))}
                        </TableBody>
                    );
                }
            }
            if (props.detail === 'montant') {
                const boucleBody = [];
                for (let index = 0; index < Object.keys(props.data).length; index++) {
                    boucleBody.push(
                        <TableRow>
                            <td>{Object.keys(props.data)[index]}</td>
                            <td className="textRight">{euroFormate(Object.values(props.data)[index] === null ? 0 : Object.values(props.data)[index])}</td>
                        </TableRow>,
                    );
                }
                body = <TableBody>{boucleBody}</TableBody>;
            }
            return (
                <Table aria-label="simple table" style={tableauStyle} className={css.xs_w100}>
                    {tableauHeader}
                    {body}
                </Table>
            );
        }

        return <></>;
    }

    function calculMontant(data, indice) {
        let montant = 0;
        for (let index = 0; index < data.length; index++) {
            montant = +montant + +data[index][indice];
        }
        return montant;
    }

    function TableauDetailTotal(props) {
        let body;
        const objectMontant = {
            'Montant total': calculMontant(props.data, 'montantTotal'),
            'Montant déjà Réglé': calculMontant(props.data, 'MontantDejaRegle'),
            'Reste à régler': calculMontant(props.data, 'montantTotal') - calculMontant(props.data, 'MontantDejaRegle'),
        };
        const boucleBody = [];
        for (let index = 0; index < Object.keys(objectMontant).length; index++) {
            boucleBody.push(
                <TableRow>
                    <td>{Object.keys(objectMontant)[index]}</td>
                    <td className="textRight">{euroFormate(Object.values(objectMontant)[index] === null ? 0 : Object.values(objectMontant)[index])}</td>
                </TableRow>,
            );
        }
        body = <TableBody>{boucleBody}</TableBody>;
        return (
            <div className="ml2 w30 xs_w100 m_w50">
                <CalculCotisation className="mb2 floatRight" idFonction={fonctionCotisation.Calcul_cotisation} />
                <Table aria-label="simple table">{body}</Table>
            </div>
        );
    }

    function reglementFiltre(Reglement) {
        props.filtre([Reglement]);
    }

    async function saveReglement(idCotisation, idReglement) {
        const data = {
            idReglement: idReglement ? idReglement : null,
            fk_cotisation: idCotisation,
            montant: dataInput.montant,
            fk_mode: reducerFiltre.Reglement,
            date: DateHeureFormat({ BDD: 'date', date: !dataInput.datePaiment ? new Date() : dataInput.datePaiment }),
            valide: valider,
            quittance: dataInput.quittance,
            abonnement: null,
        };
        const response = await POST(`/api/reglements/${idCotisation}`, data, {
            redirectOnError: false,
        });
        if (response.success) {
            setOpen({ open: false });
            props.notification({ message: 'Règlement enregistré', status: true });
            props.removeRecherche();
            await recupDataCotisation();
            setValeurRecherche({ refresh: !valeurRecherche.refresh, valeur: document.getElementById('barreRecherche').value });
        }
    }

    async function resiliation(idReglement) {
        const response = await GET(`/api/reglements/resiliationAbonnement?fk_reglement=${idReglement}`, undefined, {
            redirectOnError: false,
        });
        props.notification({
            message: response.success ? "Résiliation de l'abonnement effectué ok" : response.message,
            status: response.success,
        });
        if (response.success) {
            setDataCotisation(response.data);
            setOpen({ open: false });
        }
    }

    async function deleteReglement(idCotisation) {
        const response = await DELETE(`/api/reglements/${idCotisation}`);
        if (response.success) {
            setOpen({ open: false });
            props.notification({ message: 'Règlement supprimé', status: true });
            props.removeRecherche();
            await recupDataCotisation();
            setValeurRecherche({ refresh: !valeurRecherche.refresh, valeur: document.getElementById('barreRecherche').value });
        }
    }

    async function recupDataCotisation() {
        const data = {
            fk_anneeScolaire: reducerFiltre.AnneeScolaire,
            fk_site: reducerFiltreMultiple.Site,
        };
        const response = await POST('/api/cotisations/liste', data);
        setDataCotisation(response.data);
    }

    async function recupFiltre() {
        const anneeScolaireFiltre = await GET('/api/anneesScolaire/list');
        setTableauFiltre((prevState) => ({
            ...prevState,
            anneeScolaire: anneeScolaireFiltre.data,
        }));
        const reglementFiltre = await GET('/api/cotisations/reglementsModes');
        let tableauReglement = [];
        if (testDroit(user[usurpation].roleFonction, fonctionCotisation.Exonération_direction)) {
            tableauReglement = reglementFiltre.data;
        } else {
            tableauReglement = reglementFiltre.data.filter((e) => e.libelleMode !== 'Exonération direction');
        }
        setTableauFiltre((prevState) => ({
            ...prevState,
            reglement: tableauReglement,
        }));
        const siteFiltre = await GET('/api/sites/liste');
        setTableauFiltre((prevState) => ({
            ...prevState,
            site: siteFiltre.data,
        }));
    }
    async function getCorrespondances() {
        const data = user[usurpation].antennes.map((e) => `fk_antenne[]=${e.fk_antenne}`);
        const correspondances = await GET('/api/sites/correspondances?' + data.join('&'), undefined, {
            redirectOnError: false,
        });
        props.filtreMultiple([{ Site: correspondances.data }]);
        props.filtre([{ AnneeScolaire: user.idAnneeScolaire }]);
    }

    async function getOuverture() {
        setReglementOuvert(false);
        const response = await GET('/api/parametres/list?categorie=reglement', undefined, { redirectOnError: false });
        if (response) {
            const indexFermeture = response.data.findIndex((e) => e.cle === 'reglementsFermeture');
            if (indexFermeture >= 0) {
                setReglementOuvert(new Date(response.data[indexFermeture].valeur).toLocaleDateString('en-CA') >= new Date().toLocaleDateString('en-CA'));
            }
        }
    }
    async function getPaiementErreur(fk_cotisation) {
        const response = await GET(`/api/reglements/erreurReglement?fk_cotisation=${fk_cotisation}`);
        setPaimentRefus(response.data);
        setDisplayRefus(displayRefus === 'none' ? 'block' : 'none');
    }
    useEffect(() => {
        recupFiltre();
        props.addNomPage('Cotisations & règlements');
        getCorrespondances();
        getOuverture();
    }, []);

    useEffect(() => {
        recupDataCotisation();
    }, [refreshSimple, refreshMultiple]);

    return (
        <div className="listeContainer">
            {Modal()}
            <div
                style={{
                    height: '30%',
                    width: '70%',
                    overflow: 'auto',
                    zIndex: '1',
                    display: displayRefus,
                    position: 'fixed',
                    backgroundColor: '#fff',
                    border: '3px solid #000',
                    padding: '0.5% 1% 1% 1%',
                    left: '20%',
                    top: '15%',
                }}
            >
                <div className="flex alignICenter ">
                    <div className="w20 ">
                        <label>Date</label>
                    </div>
                    <div className="w70 ">
                        <p className="nomargin ">Message</p>
                    </div>
                    <ButtonCustom type={'button'} label={'Fermer'} onClick={() => setDisplayRefus('none')} className={css.ml2} />
                </div>
                <hr className="w90 nomargin"></hr>
                {paiementRefus.map((row, index) => (
                    <div>
                        <div key={index} className="flex alignICenter">
                            <div className="w20">
                                <label>{new Date(row.stamp).toLocaleString('fr-FR')}</label>
                            </div>
                            <div className="w70 ">
                                <p>{row.message}</p>
                            </div>
                        </div>
                        <hr className="w90 nomargin"></hr>
                    </div>
                ))}
            </div>
            <Container>
                <div className="flex alignICenter xs_block m_block">
                    <div className="xs_w100 w70 m_w100">
                        <SelectSimple
                            tableau={tableauFiltre.anneeScolaire}
                            inputLabel={'Année Scolaire'}
                            indice={'AnneeScolaire'}
                            classe={[css.w45, css.xs_w80]}
                            disabled={!testDroit(user[usurpation].roleFonction, fonctionCotisation.Trie_annee_scolaire)}
                        ></SelectSimple>
                        <SelectMultiple
                            tableau={tableauFiltre.site}
                            inputLabel={'Site'}
                            indice={'Site'}
                            classe={[css.w45, css.xs_w80, css.ml2]}
                            disabled={!testDroit(user[usurpation].roleFonction, fonctionCotisation.Trie_site_liste_cotisation)}
                        ></SelectMultiple>
                        <TableRecherche
                            tableau={dataCotisation}
                            className={[css.w90, css.xs_w90]}
                            indice={['nomPrenomUtilisateur']}
                            id={'barreRecherche'}
                            valeurRecherche={valeurRecherche}
                        ></TableRecherche>
                    </div>
                    {testDroit(user[usurpation].roleFonction, fonctionCotisation.Export_excel_liste_cotisation) &&
                    !window.matchMedia('(max-width: 1080px)').matches ? (
                        <div className="xs_m2 xs_mb5">
                            <SplitButton
                                options={[
                                    {
                                        label: ExportCotisation({
                                            data: dataCotisation,
                                            nomFichier: 'Cotisations et Règlements',
                                            label: 'Toutes les cotisations',
                                        }),
                                    },
                                    {
                                        label: ExportCotisation({
                                            data: dataCotisation,
                                            nomFichier: 'Cotisations et Règlements',
                                            label: 'Règlements complets',
                                        }),
                                    },
                                    {
                                        label: ExportCotisation({
                                            data: dataCotisation,
                                            nomFichier: 'Cotisations et Règlements',
                                            label: 'Règlements partiels',
                                        }),
                                    },
                                    {
                                        label: ExportCotisation({
                                            data: dataCotisation,
                                            nomFichier: 'Cotisations et Règlements',
                                            label: 'Trop-perçus',
                                        }),
                                    },
                                    {
                                        label: ExportCotisation({
                                            data: dataCotisation,
                                            nomFichier: 'Cotisations et Règlements',
                                            label: 'Pas de règlement',
                                        }),
                                    },
                                ]}
                            />
                        </div>
                    ) : null}

                    <TableauDetailTotal data={dataCotisation} />
                </div>
                <PaginationTable data={props.recherche.fn(dataCotisation)} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHeadCustom data={tableHead}></TableHeadCustom>
                        <TableBody className={css.tbody}>
                            {setData(
                                props.recherche.fn,
                                dataCotisation,
                                props.Page[0].page,
                                props.Page[0].rowsPerPage,
                                props.orderState,
                                props.orderByState,
                            ).map((row, index) => (
                                <TableRow key={index} className={[css.trbody, css.alignTop]}>
                                    <TableCell className={css.td} component="th" scope="row">
                                        <div>Nom</div>
                                        <Link to={`/fiche-responsable/${row.idUtilisateur}`}>{row.nomPrenomUtilisateur}</Link>
                                    </TableCell>
                                    <TableCell className={[css.td, css.xs_mb10]} component="th" scope="row">
                                        <div>Site</div>
                                        {row.libelleSite}
                                    </TableCell>
                                    <TableCell component="th" scope="row" className={[css.xs_p0, css.xs_w80, css.w50em]}>
                                        <TableauDetailPaiment
                                            detail={'montant'}
                                            data={{
                                                'Montant total': row.montantTotal,
                                                'Montant déjà Réglé': row.MontantDejaRegle,
                                                'Reste à régler': row.montantTotal - row.MontantDejaRegle,
                                            }}
                                        />
                                        <div className="flex mb2">
                                            {reglementOuvert && testDroit(user[usurpation].roleFonction, fonctionCotisation.Ajouter_un_reglement) ? (
                                                <ButtonCustom
                                                    type={'button'}
                                                    className={[css.m2, css.xs_none]}
                                                    label={'Ajouter un reglement'}
                                                    onClick={() => {
                                                        setDataInput({
                                                            quittance: '',
                                                            montant: row.montantTotal - row.MontantDejaRegle,
                                                            datePaiment: '',
                                                        });
                                                        props.filtre({ Reglement: '' });
                                                        setOpen({
                                                            open: true,
                                                            idCotisation: row.idCotisation,
                                                            content: 'save',
                                                        });
                                                        setValider(false);
                                                    }}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                            {row.erreurPaiement && testDroit(user[usurpation].roleFonction, fonctionCotisation.paiement_refus) ? (
                                                <ButtonCustom
                                                    type={'button'}
                                                    className={[css.m2, css.xs_none]}
                                                    label={'Paiement en erreur'}
                                                    onClick={() => getPaiementErreur(row.idCotisation)}
                                                />
                                            ) : null}
                                        </div>
                                        <TableauDetailPaiment idCotisation={row.idCotisation} detail={'facture'} data={row.concatCotisation} />
                                    </TableCell>
                                    <TableCell
                                        className={[css.td, css.xs_p0, css.w25, css.xs_w100]}
                                        dangerouslySetInnerHTML={{
                                            __html: row.explication,
                                        }}
                                    ></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationTable data={props.recherche.fn(dataCotisation)} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
            </Container>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
        filtreMultiple(filtreMultiple) {
            dispatch({ type: 'multiple', filtreMultiple });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
        removeFiltreMultiple() {
            dispatch({ type: 'removeFiltreMultiple' });
        },
        removeRecherche() {
            dispatch({ type: 'removeRecherche' });
        },
    };
}

function mapStateToProps(state) {
    return {
        Page: state.Pagination,
        recherche: state.ReponseRecherche,
        orderState: state.Order,
        orderByState: state.OrderBy,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Cotisation);
