/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { Button, Select, Input, Switch, TimePicker, InputNumber } from 'antd';

import { GET, POST } from '../../../components/Utils/requete';
import { Jour } from '../../../components/Utils/consts';
import Reduire from '../../../components/organism/Reduire/Reduire';
import { calculDuree } from '../../../components/Utils/calculTemp';
import Wait from '../../../components/atoms/Wait';
import { testDroit } from '../../../components/Utils/testDroit';
import ListePresences from '../../../components/organism/Presence/Liste';

import AtelierEleve from './Eleve';
import AtelierEnseignants from './Enseignants';

import { requeteFiltre } from '../consts';
import * as fonctionAtelier from '../../../fonctionsRoles/atelier';
import formatSelect from '../../../components/Utils/FormatSelect';
import dayjs from 'dayjs';

const { RangePicker } = TimePicker;
const { TextArea } = Input;

const FicheAtelier = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    let { id } = useParams();
    const usurpation = user.userC;
    const previousPathURL = useSelector((state) => state.PreviousPath.atelier);
    const retour = previousPathURL === undefined ? '/accueil' : previousPathURL;
    const [donneeAtelier, setDonneeAtelier] = useState({
        idAtelier: null,
        fk_anneeScolaire: null,
        fk_typeAtelier: null,
        fk_site: null,
        fk_salle: null,
        fk_typeRepetition: null,
        jour: null,
        heureDebut: '00:00',
        heureFin: '00:00',
        fk_visibilite: null,
        fk_cycle: null,
        fk_annee: null,
        fk_groupe: null,
        commentaire: '',
        evaluation1: '',
        evaluation2: '',
        fk_groupeAtelier: null,
        libelleType: '',
        individuel: null,
        propriete: [],
        libelleGroupe: '',
        dureeAtelier: null,
        maximumEleves: 0,
        enseignantRemplacant: 0,
    });
    const [tableauFiltre, setTableauFiltre] = useState({
        AnneeScolaire: [],
        Site: [],
        Cycle: [],
        GroupeAtelier: [],
        Annee: [],
        Groupe: [],
        Visibilite: [],
        Repetition: [],
        Statut: [],
        Instrument: [],
        SousGroupeAtelier: [],
        Salle: [],
        Jour: formatSelect(Jour, 'id', 'lib'),
    });

    const [dataFetched, setDataFetched] = useState(false);
    const [atelierType, setAtelierType] = useState([]);
    const [propriete, setPropriete] = useState([]);
    const [dataAtelier, setDataAtelier] = useState([]);
    async function recupDataAtelier(duplicateID) {
        for (let elem = 0; elem < requeteFiltre.length; elem++) {
            const requete = await GET(requeteFiltre[elem].url);
            setTableauFiltre((prevState) => ({
                ...prevState,
                [Object.keys(tableauFiltre)[elem]]: formatSelect(requete.data, requeteFiltre[elem].id, requeteFiltre[elem].libelle),
            }));
        }

        const sousGroupeAtelierFiltre = await POST('/api/ateliers/ateliersTypes');
        setAtelierType(sousGroupeAtelierFiltre.data);
        const salleFiltre = await POST('/api/salles/liste');
        setTableauFiltre((prevState) => ({
            ...prevState,
            Salle: salleFiltre.data,
            SousGroupeAtelier: sousGroupeAtelierFiltre.data,
        }));

        const realId = duplicateID ? duplicateID : id;
        if (realId !== '0') {
            const response = await GET(`/api/ateliers/${realId}`);
            setDataAtelier(response.data);
            const selectAtelierType = sousGroupeAtelierFiltre.data.filter((e) => e.idTypeAtelier === response.data.fk_typeAtelier);
            if (selectAtelierType.length > 0) setPropriete(selectAtelierType[0].propriete);
            props.addNomPage(`Fiche atelier - ${response.data.libelleType}`);
            setDonneeAtelier(response.data);
        } else {
            props.addNomPage('nouvel atelier');
        }
        setDataFetched(true);
    }
    async function saveAtelier(action) {
        if (id === '0' || action === 'aller') donneeAtelier.idAtelier = null;
        if (!donneeAtelier.fk_anneeScolaire || !donneeAtelier.fk_typeAtelier || !donneeAtelier.fk_groupeAtelier) {
            return props.notification({
                message: "Veuillez remplir les champs année scolaire, groupe atelier et  sous groupe d'ateliers",
            });
        }
        console.log(action);
        const response = await POST(`/api/ateliers/${id}`, donneeAtelier);
        if (response.success) {
            props.notification({
                message: "L'atelier a été enregistré.",
                status: true,
            });
            switch (action) {
                case 'rester':
                    props.history.push(`/fiche-atelier/${response.data.idAtelier}`);
                    break;
                case 'aller':
                    props.history.push(`/fiche-atelier/${response.data.idAtelier}`);
                    recupDataAtelier(response.data.idAtelier);
                    break;
                default:
                    break;
            }
        }
    }

    useEffect(() => {
        recupDataAtelier();
    }, []);
    return (
        <div>
            {!dataFetched ? <Wait /> : null}
            <div className="flex spaceBetween  AlignICenter p2">
                <div className="w65">
                    <Link
                        to={retour}
                        onClick={() => {
                            props.savePathAtelier(undefined);
                        }}
                    >
                        <Button danger className="xs_w90 mr2 xs_ml2">
                            retour
                        </Button>
                    </Link>
                    {testDroit(user[usurpation].roleFonction, fonctionAtelier.Enregistre_et_quitter) && (
                        <Link to="/liste-ateliers">
                            <Button
                                className="cbleu border1bleu mr2 xs_w90"
                                onClick={() => {
                                    saveAtelier();
                                }}
                            >
                                Enregistrer & Quitter
                            </Button>
                        </Link>
                    )}
                    {testDroit(user[usurpation].roleFonction, fonctionAtelier.Enregistrer_atelier) && (
                        <Button
                            className="cbleu border1bleu mr2 xs_w90"
                            onClick={() => {
                                saveAtelier('rester');
                            }}
                        >
                            Enregistrer & Rester
                        </Button>
                    )}
                    {testDroit(user[usurpation].roleFonction, fonctionAtelier.Duplication_atelier) && (
                        <Button
                            className="cbleu border1bleu mr2 xs_w90"
                            onClick={() => {
                                saveAtelier('aller');
                            }}
                        >
                            Dupliquer & aller
                        </Button>
                    )}
                </div>
                <div className="flex w35">
                    Cours effectué par un enseignant remplacant{' '}
                    <Switch
                        className="ml5"
                        checked={donneeAtelier.enseignantRemplacant ? true : false}
                        onChange={() =>
                            setDonneeAtelier((prevState) => ({
                                ...prevState,
                                enseignantRemplacant: donneeAtelier.enseignantRemplacant ? 0 : 1,
                            }))
                        }
                    />
                </div>
            </div>
            <div className="flex xs_directionColumn m_directionColumn">
                <div className="w100 m2  xs_w90 m_w90">
                    <div className="flex">
                        <h3 className="w100 nomargin">Information générale </h3>
                    </div>
                    <hr></hr>
                    <Reduire>
                        <div className="textRight alignICenter flex mb10px xs_block xs_textLeft">
                            <label className="w35 xs_inlineBlock">Année Scolaire</label>
                            <Select
                                className="nomargin ml30px w50"
                                value={donneeAtelier.fk_anneeScolaire}
                                onChange={(values) =>
                                    setDonneeAtelier((prevState) => ({
                                        ...prevState,
                                        fk_anneeScolaire: values,
                                    }))
                                }
                                options={tableauFiltre.AnneeScolaire}
                            />
                        </div>
                        <div className="textRight alignICenter flex mb10px xs_block xs_textLeft">
                            <label className="w35 xs_inlineBlock">Groupe d'Ateliers</label>
                            <Select
                                className="nomargin ml30px w50"
                                value={donneeAtelier.fk_groupeAtelier}
                                onChange={(values) => {
                                    setDonneeAtelier((prevState) => ({
                                        ...prevState,
                                        fk_groupeAtelier: values,
                                        fk_typeAtelier: null,
                                        fk_typeRepetition: null,
                                        fk_visibilite: null,
                                        fk_cycle: null,
                                        fk_annee: null,
                                        fk_groupe: null,
                                    }));
                                    setPropriete([]);
                                }}
                                options={tableauFiltre.GroupeAtelier}
                            />
                        </div>
                        <div className="textRight alignICenter flex mb10px xs_block xs_textLeft">
                            <label className="w35 xs_inlineBlock">Sous-groupe d'Ateliers</label>
                            <Select
                                className="nomargin ml30px w50"
                                value={donneeAtelier.fk_typeAtelier}
                                onChange={(values) => {
                                    setDonneeAtelier((prevState) => ({
                                        ...prevState,
                                        fk_typeAtelier: values,
                                    }));
                                    const selectAtelierType = atelierType.filter((e) => e.idTypeAtelier === values);
                                    if (selectAtelierType.length > 0) setPropriete(selectAtelierType[0].propriete);
                                }}
                                options={formatSelect(
                                    tableauFiltre.SousGroupeAtelier.filter((e) => e.fk_groupeAtelier === donneeAtelier.fk_groupeAtelier),
                                    'idTypeAtelier',
                                    'libelleType',
                                )}
                            />
                            {console.log(tableauFiltre.SousGroupeAtelier.filter((e) => e.fk_groupeAtelier === donneeAtelier.fk_groupeAtelier))}
                        </div>
                        <div className="textRight alignICenter flex mb10px xs_block xs_textLeft">
                            <label className="w35 xs_inlineBlock">Site</label>
                            <Select
                                className="nomargin ml30px w50"
                                value={donneeAtelier.fk_site}
                                onChange={(values) =>
                                    setDonneeAtelier((prevState) => ({
                                        ...prevState,
                                        fk_site: values,
                                        fk_salle: null,
                                    }))
                                }
                                options={tableauFiltre.Site}
                            />
                        </div>
                        <div className="textRight alignICenter flex mb10px xs_block xs_textLeft">
                            <label className="w35 xs_inlineBlock">Salle</label>
                            <Select
                                className="nomargin ml30px w50"
                                value={donneeAtelier.fk_salle}
                                onChange={(values) =>
                                    setDonneeAtelier((prevState) => ({
                                        ...prevState,
                                        fk_salle: values,
                                    }))
                                }
                                options={formatSelect(
                                    tableauFiltre.Salle.filter((e) => e.fk_site === donneeAtelier.fk_site),
                                    'idSalle',
                                    'libelleSalle',
                                )}
                            />
                        </div>
                        <div className="textRight alignICenter flex mb10px xs_block xs_textLeft">
                            <label className="w35 xs_inlineBlock">Jour</label>
                            <Select
                                className="nomargin ml30px w50"
                                value={donneeAtelier.jour}
                                onChange={(values) =>
                                    setDonneeAtelier((prevState) => ({
                                        ...prevState,
                                        jour: values,
                                    }))
                                }
                                options={tableauFiltre.Jour}
                            />
                        </div>

                        {propriete.length !== 0 && (
                            <div className="textRight alignICenter flex mb10px xs_block xs_textLeft">
                                <label className="w35 xs_inlineBlock">Fréquence</label>
                                <Select
                                    className="nomargin ml30px w50"
                                    value={donneeAtelier.fk_typeRepetition}
                                    onChange={(values) =>
                                        setDonneeAtelier((prevState) => ({
                                            ...prevState,
                                            fk_typeRepetition: values,
                                        }))
                                    }
                                    options={tableauFiltre.Repetition}
                                />
                            </div>
                        )}
                        {propriete.length !== 0 && (
                            <div className="textRight alignICenter flex mb10px xs_block xs_textLeft">
                                <label className="w35 xs_inlineBlock">Visibilité</label>
                                <Select
                                    className="nomargin ml30px w50"
                                    value={donneeAtelier.fk_visibilite}
                                    onChange={(values) =>
                                        setDonneeAtelier((prevState) => ({
                                            ...prevState,
                                            fk_visibilite: values,
                                        }))
                                    }
                                    options={tableauFiltre.Visibilite}
                                />
                            </div>
                        )}
                        {propriete.includes('Cycle') && (
                            <div className="textRight alignICenter flex mb10px xs_block xs_textLeft">
                                <label className="w35 xs_inlineBlock">Cycle</label>
                                <Select
                                    className="nomargin ml30px w50"
                                    value={donneeAtelier.fk_cycle}
                                    onChange={(values) =>
                                        setDonneeAtelier((prevState) => ({
                                            ...prevState,
                                            fk_cycle: values,
                                        }))
                                    }
                                    options={tableauFiltre.Cycle}
                                />
                            </div>
                        )}
                        {propriete.includes('Annee') && (
                            <div className="textRight alignICenter flex mb10px xs_block xs_textLeft">
                                <label className="w35 xs_inlineBlock">Année</label>
                                <Select
                                    className="nomargin ml30px w50"
                                    value={donneeAtelier.fk_annee}
                                    onChange={(values) =>
                                        setDonneeAtelier((prevState) => ({
                                            ...prevState,
                                            fk_annee: values,
                                        }))
                                    }
                                    options={tableauFiltre.Annee}
                                />
                            </div>
                        )}
                        {propriete.includes('Groupe') && (
                            <div className="textRight alignICenter flex mb10px xs_block xs_textLeft">
                                <label className="w35 xs_inlineBlock">Groupe</label>
                                <Select
                                    className="nomargin ml30px w50"
                                    value={donneeAtelier.fk_groupe}
                                    onChange={(values) =>
                                        setDonneeAtelier((prevState) => ({
                                            ...prevState,
                                            fk_groupe: values,
                                        }))
                                    }
                                    options={tableauFiltre.Groupe}
                                />
                            </div>
                        )}
                        {testDroit(user[usurpation].roleFonction, fonctionAtelier.Nombre_eleve) && (
                            <div className="textRight alignICenter flex mb10px xs_block xs_textLeft">
                                <label className="w35 xs_inlineBlock">Nombre d'élèves</label>
                                <InputNumber
                                    className="nomargin ml30px w50"
                                    value={donneeAtelier.maximumEleves}
                                    onChange={(value) =>
                                        setDonneeAtelier((prevState) => ({
                                            ...prevState,
                                            maximumEleves: value,
                                        }))
                                    }
                                />
                            </div>
                        )}
                        <div className="textRight alignICenter flex mb10px xs_block xs_textLeft">
                            <label className="w35 xs_inlineBlock">Temps de cours</label>
                            <div className="nomargin  ml30px flex directionColumn w50">
                                <RangePicker
                                    disabledTime={(current) => ({ disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 23] })}
                                    minuteStep={5}
                                    value={[
                                        dayjs().set('hour', donneeAtelier.heureDebut.split(':')[0]).set('minute', donneeAtelier.heureDebut.split(':')[1]),
                                        dayjs().set('hour', donneeAtelier.heureFin.split(':')[0]).set('minute', donneeAtelier.heureFin.split(':')[1]),
                                    ]}
                                    format="HH:mm"
                                    onChange={(time, timeString) =>
                                        setDonneeAtelier((prevState) => ({
                                            ...prevState,
                                            heureDebut: timeString[0],
                                            heureFin: timeString[1],
                                        }))
                                    }
                                />
                                <label className="fontLittleSmallGrey">Durée: {calculDuree(donneeAtelier.heureDebut, donneeAtelier.heureFin)} minutes</label>
                            </div>
                        </div>
                    </Reduire>
                    <ListePresences fk_atelier={id} className="mt5" />
                </div>
                <div className="w100 m2  xs_w90 m_w90">
                    {donneeAtelier.fk_anneeScolaire && (
                        <>
                            <AtelierEnseignants id={id} anneeScolaire={donneeAtelier.fk_anneeScolaire} site={donneeAtelier.fk_site} />
                            <AtelierEleve
                                id={id}
                                dataAtelier={dataAtelier}
                                maximumEleves={donneeAtelier.maximumEleves}
                                anneeScolaire={donneeAtelier.fk_anneeScolaire}
                                site={donneeAtelier.fk_site}
                                sousGroupeAtelier={donneeAtelier.fk_typeAtelier}
                            />
                        </>
                    )}
                    <div>
                        <h3 className="w100 mt2">Commentaire</h3>
                        <hr></hr>
                        <div className="textRight alignICenter flex mb10px pl2">
                            <TextArea
                                rows={2}
                                value={donneeAtelier.commentaire}
                                onChange={({ target }) =>
                                    setDonneeAtelier((prevState) => ({
                                        ...prevState,
                                        heureDebut: target.value,
                                    }))
                                }
                            />
                        </div>
                    </div>
                    <div>
                        <h3 className="w100">Évaluation 1</h3>
                        <hr></hr>
                        <div className="textRight alignICenter flex mb10px pl2">
                            <TextArea
                                rows={2}
                                value={donneeAtelier.evaluation1}
                                onChange={({ target }) =>
                                    setDonneeAtelier((prevState) => ({
                                        ...prevState,
                                        evaluation1: target.value,
                                    }))
                                }
                            />
                        </div>
                    </div>
                    <div>
                        <h3 className="w100">Évaluation 2</h3>
                        <hr></hr>
                        <div className="textRight alignICenter flex mb10px pl2">
                            <TextArea
                                rows={2}
                                value={donneeAtelier.evaluation2}
                                onChange={({ target }) =>
                                    setDonneeAtelier((prevState) => ({
                                        ...prevState,
                                        evaluation2: target.value,
                                    }))
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        savePathAtelier(pathAtelier) {
            dispatch({ type: 'savePathAtelier', pathAtelier });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}

export default connect(null, mapDispatchToProps)(FicheAtelier);
