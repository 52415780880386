/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { Card, Tabs } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';

import Container from '../../components/atoms/Container/container';
import { GET } from '../../components/Utils/requete';
import CustomTooltips from '../../components/organism/Tooltip/customTooltip';
import { testDroit } from '../../components/Utils/testDroit';

import { Mofication_informations, Mofication_informations_CST } from '../../fonctionsRoles/acceuil';

const OngletAcceuil = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const [informationSyndicale, setInformationSyndicale] = useState([]);
    const [informationDirection, setInformationDirection] = useState([]);
    const history = useHistory();
    const tabOnglet = [
        {
            label: 'Direction',
            key: '1',
            children: <Information_Direction />,
        },
        {
            label: 'Représentants du personnel',
            key: '2',
            children: <Information_CST />,
        },
    ];

    if (user[usurpation].role === 'Responsable') {
        history.push(`/accueilResponsable`);
    }
    async function getInformation() {
        const response = await GET(`/api/informations/list?lecture=true&visible=1&groupe=${user[usurpation].groupe}`);
        const tableauInforamtion = { direction: [], syndicat: [] };
        for (let index = 0; index < response.data.length; index++) {
            if (response.data[index].type[0] === 'direction') tableauInforamtion.direction.push(response.data[index]);

            if (response.data[index].type[0] === 'syndicat') tableauInforamtion.syndicat.push(response.data[index]);
        }
        setInformationDirection(tableauInforamtion.direction);
        setInformationSyndicale(tableauInforamtion.syndicat);
    }

    function InformationHeader(props) {
        const { information } = props;
        return (
            <div className="m2">
                <Link to={`/edition-information/${information.idInformation}?type=${information.type[0]}`}>
                    <CustomTooltips title="Éditer l'information">
                        <FileSearchOutlined className="floatRight fontSize2_5em" />
                    </CustomTooltips>
                </Link>
                <span className="fontLittleSmallGrey">Auteur : {information.nomPrenomUtilisateur}</span>
                <br />
                <span className="fontLittleSmallGrey">Date : {information.date}</span>
                {information.type[0] === 'direction' && (
                    <>
                        <br />
                        <span className="fontLittleSmallGrey">A destination de : {information.destinataires}</span>
                    </>
                )}
            </div>
        );
    }

    function Information(props) {
        const { information } = props;
        let header = '';
        const testDroitTypeInformation =
            information.type[0] === 'direction'
                ? testDroit(user[usurpation].roleFonction, Mofication_informations)
                : testDroit(user[usurpation].roleFonction, Mofication_informations_CST);
        if (testDroitTypeInformation) {
            header = <InformationHeader information={information} />;
        }

        return (
            <Card className="mb5">
                <span variant="h5" component="h2">
                    {information.titre}
                </span>
                {header}
                <div
                    dangerouslySetInnerHTML={{
                        __html: information.message,
                    }}
                    className="leading100"
                ></div>
            </Card>
        );
    }
    useEffect(() => {
        getInformation();
        props.addNomPage(`Accueil`);
    }, []);
    function Information_Direction() {
        return (
            informationDirection.length !== 0 &&
            informationDirection.map((row, index) => {
                return <Information information={row} key={index} />;
            })
        );
    }
    function Information_CST() {
        return (
            informationSyndicale.length !== 0 &&
            informationSyndicale.map((row, index) => {
                return <Information information={row} key={index} />;
            })
        );
    }

    return (
        <>
            <h1 className="textCenter">Informations et Actualités</h1>
            {window.matchMedia('(max-width: 576px)').matches && (
                <Tabs
                    defaultActiveKey="1"
                    type="card"
                    items={tabOnglet.map((row) => {
                        return {
                            label: row.label,
                            key: row.key,
                            children: row.children,
                        };
                    })}
                />
            )}
            {window.matchMedia('(min-width: 576px)').matches && (
                <div className="flex mr5">
                    <Container className="m2I">
                        <h3 className="textCenter">Direction</h3>
                        {Information_Direction()}
                    </Container>
                    <Container className="m2I borderLeft pl1">
                        <h3 className="textCenter">Représentants du personnel</h3>
                        {Information_CST()}
                    </Container>
                </div>
            )}
        </>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
    };
}
export default connect(null, mapDispatchToProps)(OngletAcceuil);
